<template>
    <div class="wrap">
        <van-search
            v-model="name"
            placeholder="请输入流程名称/流程发起人搜索"
            @search="searchdata"
			class="searchPlace"
        ></van-search>

        <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            style="min-height: 100vh"
        >
            <van-list
                v-model="loading"
                :finished="finished"
                @load="getData"
            >
                <template v-if="list.length > 0">
                    <van-cell-group
                        v-for="(item, index) in list"
                        :key="index"
                        @click="changeViewSatute(item)"
                        :url="
                                '/#/historyInfo?id=' +
                                item.instanceId +
                                '&processDefinitionId=' +
                                item.processId +
                                '&page=copy' +
                                isHasNav
                            "
                    >
                        <van-cell>
                            <template #title>
                                <div class="title">
                                    <div class="line"></div>
                                    <div style="font-weight: 600">{{ item.title }}</div>
                                </div>
                            </template>
                        </van-cell>

                        <!-- <van-cell
                            :title="'抄送编号：'"
                            :value="item.copyId"
                        ></van-cell> -->

                        <van-cell :title="'查看状态：'">
                            <span
                                v-if="item.readState == 1"
                                style="color: #999"
                            >
                                已读
                            </span>
                            <span
                                v-else
                                style="color: red"
                            >
                                未读
                            </span>
                        </van-cell>

                      

                        <van-cell
                            :title="'发起人：'"
                            :value="item.originatorName"
                        ></van-cell>

                        <van-cell
                            :title="'提交时间：'"
                            :value="formatTime(item.createTime)"
                        ></van-cell>

                        <!-- 
                        <van-cell
                            :title="'流程状态：'"
                            :class="item.status === 1 ? 'blue' : 'red'"
                            :value="item.status === 1 ? '进行中' : '已完成'"
                        ></van-cell> 
                        -->

                        <!-- <van-cell
                            :title="'查看详情'"
                            is-link
                            :url="
                                '/#/historyInfo?id=' +
                                item.instanceId +
                                '&processDefinitionId=' +
                                item.processId +
                                '&page=copy' +
                                isHasNav
                            "
                            :clickable="true"
                            @click="changeViewSatute(item.copyId)"
                        ></van-cell> -->
                    </van-cell-group>
                </template>

                <div
                    class="empty"
                    v-if="list.length == 0 && finished"
                >
                    <van-empty
                        description="没有内容，空空如也~"
                        :image="require('@/assets/img/mag_pic_noinformation.svg')"
                    ></van-empty>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import { getCopyList, changeCopyRead } from "@/api/flow";
    import { formatTime } from "@/utils/DateUtil";

    export default {
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                page: 1,
                size: 10,
                list: [],

                name: "",
            };
        },
        components: {},
        watch: {},
        created() {
        },
        activated() {
            //需要刷新的页面方法
            // this.getData();
        },
        methods: {
            // 切换抄送查看状态
            changeViewSatute(item) {
                this.$router.push({
                    path:
                    '/historyInfo?id=' +
                    item.instanceId +
                    '&processDefinitionId=' +
                    item.processId +
                    '&page=copy' +
                    this.isHasNav
                })
                changeCopyRead({ copyId:item.copyId });
            },
            searchdata() {
                this.list = [];
                this.onRefresh();
                // this.page = 1;
                // this.list = [];
                // this.finished = false;
                // this.getData();
            },
            getData() {
                try {
                    let params = {
                        pageNo: this.page,
                        pageSize: this.size,
                        keyword: this.name,
                    };
                    getCopyList(params).then((res) => {
                        if (res.status == 200) {
                            if (this.refreshing) {
                                this.list = [];
                                this.refreshing = false;
                            }
                            if (res.data.list && res.data.list.length > 0) {
                                this.list = this.list.concat(res.data.list);
                                this.page += 1;
                            }
                            this.loading = false;
                            if (this.list.length >= res.data.total) {
                                this.finished = true;
                            }
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    this.finished = true;
                    //TODO handle the exception
                }
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.loading = true;
                this.page = 1;
                // 重新加载数据
                this.getData();
            },
            formatTime,
        },
        computed: {
            // 从哪个页面跳转过来的
            isHasNav() {
                return this.$route.query.from == "jntPc" ? "&hasNav=1" : "";
            },
        },
    };
</script>

<style lang="less" scoped>
    .wrap {
        padding: 0 10px;

        .title {
            display: flex;
            align-items: center;
            .line {
                width: 0.2rem;
                height: 0.8rem;
                background: #037ae7;
                margin-right: 10px;
            }
        }
		.searchPlace{
			/deep/.van-cell__value{
				display: flex;
				align-items: center;
			}
            /deep/.van-field__body{
                width:100%
            }
			
		}

        /deep/ .blue .van-cell__value {
            color: #037ae7;
        }

        /deep/ .red .van-cell__value {
            color: rgb(255, 77, 79);
        }

        /deep/ .van-cell-group {
            margin: 12px 0;
        }
    }
</style>
