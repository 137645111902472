/* eslint-disable no-unused-vars */
import { del, get, post, put,delPost } from "@/utils/HttpUtil";

/* 获取所有流程表单列表  process-definition/list */
export const getSendFlowList = (params) => get("/bpm/process-definition/list", params);

/* 获取我的请求的流程表单列表  saveInstance/my-page */
export const getMyReqFlowList = (params) => get("/bpm/saveInstance/my-page", params);

export const getSendFlowInfo = (params) => get("/bpm/process-definition/info", params);

/** 获得我的实例分页列表  task/todo-page */
export const getHistoryFlowList = (params) => get("/bpm/process-instance/my-page", params);

/** 获取抄送的任务分页列表  task/copyList */
export const getCopyList = (params) => get("/bpm/task/copyList", params);

export const getTodoFlowList = (params) => get("/bpm/task/todo-page", params);

/** 生成指定流程实例的高亮流程图,只高亮进行中的任务。 activity/list */
export const getActiveList = (params) => get("/bpm/activity/list", params);

/** 获得指定流程实例 - 流程详情  process-instance/get */
export const getProcessInstance = (params) => get("/bpm/process-instance/get", params);

/** 获得指定流程实例的任务列表 - 多节点  task/list-by-process-instance-id */
export const getMultipleNodes = (params) => get("/bpm/task/list-by-process-instance-id", params);

/** 流程 撤回  process-instance/retract */
export const retractFlow = (params) => get("/bpm/process-instance/retract", params);

/** 获取 Done 已办任务分页 流程  task/done-page */
export const getDoDoneFlowList = (params) => get("/bpm/task/done-page", params);

/** 回退 流程 task/rollback */
export const rollbackFlow = (params) => post("/bpm/task/rollback", params);

/** 驳回 流程 task/reject */
export const rejectFlow = (params) => put("/bpm/task/reject", params);

/** 通过 流程 task/approve */
export const approveFlow = (params) => put("/bpm/task/approve", params);

/** 获得员工信息 user/list-user-simple */
export const getUserList = (params) => get("/system/user/list-all-simple", params);

/** 通过 流程 task/return/list*/
export const getBackList = (params) => get("/bpm/task/return/list", params);

/** 发起 流程 之前去查 process-instance/getSaveProcess */
export const sendGetInfo = (params) => get("/bpm/process-instance/getSaveProcess", params);

/** 我的请求进入详情去查保存了的数据 saveInstance/get */
export const getMyReqInfo = (params) => get("/bpm//saveInstance/get", params);

/** 查询所有人的流程实例分页列表 process-instance/all-page */
export const getAllUserFlow = (params) => get("/bpm/process-instance/all-page", params);

/** 切换抄送查看状态 copy/read */
export const changeCopyRead = (params) => get("/bpm/copy/read", params);

/** 转发 task/update-assignee */
export const updateTaskAssignee = (params) => put("/bpm/task/update-assignee", params);


/** 多节点保存 task/save/task */
export const saveTaskInfo = (params) => post("/bpm/task/save/task", params);


/** 草稿箱删除 /bpm/saveInstance/delete */
export const deleteFlowPath = (params) => del("/bpm/saveInstance/delete", params);


/** 待办列表删除 /bpm/process-instance/cancel */
export const deleteProcessInstance = (params) => delPost("/bpm/process-instance/cancel", params);

/** 撤回任务 /bpm/task/revokeTask */
export const taskRevokeTask = (params) => post("/bpm/task/revokeTask", params);


/** 查看已办任务是否可撤回 /bpm/task/getRevocable */
export const taskGetRevocable = (params) => get("/bpm/task/getRevocable", params);