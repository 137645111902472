import dayjs from "dayjs";

/** 格式化时间，将传入的时间字符串按照指定的格式进行格式化 */
export const formatTime = (str, format = "YYYY-MM-DD HH:mm:ss") =>
  str ? dayjs(str).format(format) : dayjs().format(format);

/** 获取星期几，将传入的时间字符串转换为对应的星期几 */
export const getDayOfTheWeek = (str) =>
  str ? dayjs(str).day() : dayjs().day();

/** 获取月份里的日期 */
export const getDate = (str) => (str ? dayjs(str).date() : dayjs().date());
